<template>
  <div class="menu">
    <nav>
      <ul class="menu-links">
        <li>
          <button class="menu-link" @click="goToPage('home', 'search')">
            Wyszukaj Profil Pamięci
          </button>
        </li>

        <li>
          <button
              :class="{ active: route.path === '/profil/55-02-03' }"
              class="menu-link"
              @click="goToPage('memorial-profile-sample')"
          >
            Przykładowy Profil
          </button>
        </li>

        <li>
          <button class="menu-link" @click="goToPage('home', 'faq')">
            FAQ
          </button>
        </li>

        <li>
          <button class="menu-link" @click="goToExternalUrl(youtubeUrl)">
            <span class="button-label">Film instruktażowy</span>

            <v-icon>
              <img class="icon" alt="External link icon" src="../../assets/images/external-link-icon-black-16x16.svg" width="16" height="16">
            </v-icon>
          </button>
        </li>

        <li>
          <button :class="{ active: route.path === '/kontakt' }" class="menu-link" @click="goToPage('contact')">
            Kontakt
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { nextTick } from 'vue';

export default {
  props: {
    toggleMobileMenu: {
      type: Function,
      required: true
    },
  },
  setup() {
    const route = useRoute();
    const youtubeUrl = 'https://www.youtube.com/@takzyli_pl';

    return {
      route,
      youtubeUrl,
    };
  },
  methods: {
    goToExternalUrl(url) {
      window.open(url, '_blank');
    },
    scrollToElement(elementId) {
      const searchElement = document.getElementById(elementId);
      if (searchElement) {
        const elementPosition = searchElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: elementPosition - 100, behavior: 'smooth' });
      }
    },
    isElementVisible(element) {
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      
      return (
        rect.top >= -100 && // Allow for our -100px offset
        rect.top <= windowHeight - 100 && // Element's top is in view (with offset)
        rect.bottom >= 0 && // Element's bottom is not above viewport
        rect.bottom <= windowHeight + 100 // Allow some overflow
      );
    },
    goToPage(page, sectionId) {
      const isCurrentPath = this.route.path === '/' && page === 'home' ||
                           this.route.path === `/profil/55-02-03` && page === 'memorial-profile-sample' ||
                           this.route.path === '/kontakt' && page === 'contact';

      if (isCurrentPath) {
        if (sectionId) {
          const searchElement = document.getElementById(sectionId);

          if (searchElement && !this.isElementVisible(searchElement)) {
            this.scrollToElement(sectionId);
            this.toggleMobileMenu();
          }
        }

        return;
      }

      if (sectionId) {
        this.$router.push({ name: page }).then(() => {
          nextTick(() => {
            setTimeout(() => {
              this.scrollToElement(sectionId);
            }, 100);
          });
        });
      } else {
        this.$router.push({ name: page });
      }

      this.toggleMobileMenu();
    },
    goToCart() {
      window.location.href = `https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl`;

      this.toggleMobileMenu();
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 16px 0 8px;
  gap: 8px;
  text-decoration: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    gap: 24px;
  }
}

.menu-link {
  padding: 12px 32px 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 0.875rem;
  border-right: 4px solid transparent;
  color: #1C1B1B;
  white-space: nowrap;
  text-decoration: none;

  @media (min-width: 768px) {
    height: 24px;
    border-radius: 10px 10px 0 0;
    color: #000000;
    border: 1px solid transparent;
    padding: 0;
  }

  &:hover {
    background-color: transparent;
    border-color: #c0c0c0;
    color: #000000;

    @media (min-width: 768px) {
      background-color: transparent;
      color: #000000;
      border-color: transparent;
      border-bottom: 1px solid #0F4320;
    }
  }

  &.active {
    color: #000000;
    border-color: #c0c0c0;

    @media (min-width: 768px) {
      border-color: transparent;
      border-bottom: 1px solid #0F4320;
      cursor: default;
    }
  }

  .icon {
    align-self: flex-start;
  }
}
</style>
