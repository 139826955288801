<template>
  <MemProfCreationWrapper current-step="Krok 2 z 3: Dane osoby">
    <template #step-indicators>
      <span class="step-indicator active"></span>
      <span class="step-indicator active"></span>
      <span class="step-indicator"></span>
    </template>

    <div class="step-content">
      <h3 class="step-title">Dane osoby</h3>

      <v-divider class="step-header-separator"></v-divider>

      <div class="info-box-wrapper">
        <div class="info-box">
          <v-icon>
            <img alt="" src="@/assets/images/info-circle-blue-24x24.svg">
          </v-icon>

          <p class="info-box-message">
            Ta sekcja jako jedyna jest obowiązkowa.
          </p>
        </div>

        <div class="info-box grey">
          <img alt="" src="@/assets/images/info-circle-grey-24x24.svg">

          <p class="info-box-message">
            <span class="required-field-asterisk">* </span> - pole obowiązkowe
          </p>
        </div>
      </div>

      <p class="paragraph">
        Dodaj zdjęcie, które będzie widniało jako zdjęcie główne. Rekomendujemy jak najbardziej aktualne.
      </p>

      <DragAndDropField
          :is-rounded="true"
          :photo-url="store.memorialProfile.mainPhotoUrl.replace(/\\/g, '/')"
          :is-upload-error="isImageUploadError"
          @files-changed="handleFilesChange"
          @image-deleted="handleDeleteImage"
      />

      <p class="paragraph">Uzupełnij dane</p>

      <v-text-field
          v-model="store.memorialProfile.firstName"
          :rules="firstNameRules"
          @change="v$.firstName?.$touch"
      >
        <template #label>
          <span>Imię<span class="required-field-asterisk">*</span></span>
        </template>
      </v-text-field>

      <v-text-field
          v-model="store.memorialProfile.secondName"
          :rules="secondNameRules"
          label="Drugie imię (opcjonalne)"
          min-length="2"
          @change="v$.secondName?.$touch"
      />
      <v-text-field
          v-model="store.memorialProfile.lastName"
          :rules="lastNameRules"
          @change="v$.lastName?.$touch"
      >
        <template #label>
          <span>Nazwisko<span class="required-field-asterisk">*</span></span>
        </template>
      </v-text-field>

      <p class="paragraph">Data urodzenia</p>

      <div class="dates-inline-wrapper">
        <v-text-field
            v-model="store.memorialProfile.birthDate.day"
            :rules="birthDayRules"
            label="Dzień"
            @change="v$.birthDayErrors?.$touch"
        />
        <v-text-field
            v-model="store.memorialProfile.birthDate.month"
            :rules="birthMonthRules"
            label="Miesiąc"
            @change="v$.birthMonthErrors?.$touch"
        />
        <v-text-field
            v-model="store.memorialProfile.birthDate.year"
            :rules="birthYearRules"
            @change="v$.birthYearErrors?.$touch"
        >
          <template #label>
            <span>Rok<span class="required-field-asterisk">*</span></span>
          </template>
        </v-text-field>
      </div>

      <v-text-field
          id="birthPlace"
          v-model="store.memorialProfile.birthPlace"
          label="Miejsce urodzenia"
          prepend-inner-icon="mdi-map-marker-outline"
      />

      <p class="paragraph">Data śmierci</p>

      <div class="dates-inline-wrapper">
        <v-text-field
            v-model="store.memorialProfile.deathDate.day"
            :rules="deathDayRules"
            label="Dzień"
            @change="v$.deathDayErrors?.$touch"
        />
        <v-text-field
            v-model="store.memorialProfile.deathDate.month"
            :rules="deathMonthRules"
            label="Miesiąc"
            @change="v$.deathMonthErrors?.$touch"
        />
        <v-text-field
            v-model="store.memorialProfile.deathDate.year"
            :rules="deathYearRules"
            label="Rok"
            @change="v$.deathYearErrors?.$touch"
        >
          <template #label>
            <span>Rok<span class="required-field-asterisk">*</span></span>
          </template>
        </v-text-field>
      </div>

      <v-text-field
          id="deathPlace"
          v-model="store.memorialProfile.deathPlace"
          label="Miejsce śmierci"
          prepend-inner-icon="mdi-map-marker-outline"
      />
    </div>
  </MemProfCreationWrapper>
</template>

<script>
import { useMemorialProfileStore } from '@/stores/memorial-profile';

import DragAndDropField from '@/components/DragAndDropField.vue';
import MemProfCreationWrapper from "@/components/MemorialProfileCreationForm/MemProfCreationWrapper.vue";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    MemProfCreationWrapper,
    DragAndDropField
  },
  emits: ['update:isFormValid', 'section-updated'],
  setup(props) {
    const v$ = useVuelidate(props); // this binds vuelidate to the components properties (props)
    return {v$};
  },
  data() {
    const store = useMemorialProfileStore();

    return {
      apiUrl: process.env.VUE_APP_API_URL,
      categoryName: 'personal_details',
      isImageUploadError: false,
      store,
    };
  },
  computed: {
    firstNameRules() {
      return [
        v => !!v || 'To pole jest wymagane',
        v => (v && v.length >= 2) || 'Imię musi zawierać przynajmniej 2 znaki.',
      ];
    },
    secondNameRules() {
      return [
        v => !v || v.length >= 2 || 'Drugie imię, jeśli podane, musi zawierać przynajmniej 2 znaki.',
      ];
    },
    lastNameRules() {
      return [
        v => !!v || 'To pole jest wymagane',
        v => (v && v.length >= 2) || 'Nazwisko musi zawierać przynajmniej 2 znaki.',
      ];
    },
    birthDayRules() {
      return [
        v => !v || (v >= 1 && v <= 31) || 'Dzień musi być liczbą pomiędzy 1 a 31.',
      ];
    },
    birthMonthRules() {
      return [
        v => !v || (v >= 1 && v <= 12) || 'Miesiąc musi być liczbą pomiędzy 1 a 12.',
      ];
    },
    birthYearRules() {
      return [
        v => !!v || 'To pole jest wymagane',
        v => !v || (v.length === 4) || 'Rok musi składać się z 4 cyfr.',
        v => !v || !isNaN(v) || 'Rok musi być liczbą.',
      ];
    },
    deathDayRules() {
      return [
        v => !v || (v >= 1 && v <= 31) || 'Dzień musi być liczbą pomiędzy 1 a 31.',
      ];
    },
    deathMonthRules() {
      return [
        v => !v || (v >= 1 && v <= 12) || 'Miesiąc musi być liczbą pomiędzy 1 a 12.',
      ];
    },
    deathYearRules() {
      return [
        v => !!v || 'To pole jest wymagane.',
        v => !v || (v.length === 4) || 'Rok musi składać się z 4 cyfr.',
        v => !v || !isNaN(v) || 'Rok musi być liczbą.',
      ];
    },
  },
  watch: {
    'store.memorialProfile': {
      handler() {
        this.checkFormValidity();
      },
      deep: true,
    },
  },
  methods: {
    handleFilesChange({ files }) {
      if (!files?.length) {
        return;
      }

      const file = files[0];

      this.store.uploadImage({ file, categoryName: this.categoryName })
        .then(() => {
          this.isImageUploadError = false;
        })
        .catch(() => {
          this.isImageUploadError = true;
        });
    },
    handleDeleteImage({ photoUrl }) {
      this.store.deleteImage({ photoUrl, categoryName: this.categoryName });
    },
    checkFormValidity() {
      const requiredFieldKeys = ['firstName', 'lastName', 'birthDate.year', 'deathDate.year'];

      const hasAllValidFields = !requiredFieldKeys.some(key => {
        const value = key.includes('.') ? this.store.memorialProfile[key.split('.')[0]][key.split('.')[1]] : this.store.memorialProfile[key];
        const isDate = key.includes('year');
        const isValidValueLength = isDate ? value.length === 4 : value.length >= 2;
        return !value || !isValidValueLength;
      });

      this.$emit('update:isFormValid', hasAllValidFields);
    },
  }
};
</script>

<style lang="scss" scoped>
.paragraph {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 0 0 18px;
}
</style>
