<template>
  <v-container class="forgot-password-page">
    <v-card class="forgot-password-form" outlined>
      <v-card-title class="text-h5">Reset hasła</v-card-title>
      <v-card-text>
        <!-- Email Field -->
        <v-text-field
            label="Email"
            type="email"
            v-model="email"
            :rules="emailRules"
            outlined
            dense
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="action-buttons">
        <button
          class="button primary"
          :disabled="isLoading"
          type="submit"
          @click="submitForgotPassword"
        >
          {{ isLoading ? "Wysyłanie..." : "Wyslij" }}
        </button>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axiosInstance from "@/interceptors/axios.config";
import { emailPatternRule, fieldRequiredRule } from "@/utils/formValidators";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
    };
  },
  methods: {
    async submitForgotPassword() {
      this.isLoading = true;

      try {
        const form = new FormData();
        form.append('email', this.email);

        const response = await axiosInstance.post(this.apiUrl + 'forgot-password/', form, {
          requiresReCaptcha: true,
          reCaptchaAction: 'forgot_password'
        });

        if (response.status === 200) {
          toast.success('Email z nowym hasłem został wysłany.');

          setTimeout(() => {
            this.$router.push({ name: 'login' });
          }, 2000);
        }
      } catch (error) {
        console.error('Error during forgot password process:', error);
        toast.error('Wystąpił błąd. Spróbuj ponownie później.');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.forgot-password-page {
  max-width: 400px;
  margin: 32px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;

  @media (min-width: 768px) {
    margin: 80px auto 104px;
  }
}

.forgot-password-form {
  box-shadow: none;
}

.forgot-password-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password-form .form-group {
  display: block;
  box-shadow: none;
}

.forgot-password-form .form-group input {
  width: 100%;
  padding: 10px;
}

.forgot-password-form .button.primary {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
}

.message {
  color: green;
  text-align: center;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}
</style>
