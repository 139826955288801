<template>
  <v-card class="memorial-profile-activation-form">
    <v-card-title class="text-h5">Aktywacja profilu</v-card-title>

    <v-card-text class="activation-message">Podczas aktywacji pierwszego profilu, automatycznie utworzymy Twoje konto użytkownika. Hasło do logowania otrzymasz na podany&nbsp;e-mail.
    </v-card-text>

    <br>
    <v-form v-model="valid" @submit.prevent="submitActivation">
        <v-card-text>
            <!-- Client Email Field with Validation -->
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                type="email"
            ></v-text-field>

            <!-- Activation Key Field -->
            <v-text-field
                v-model="activation_key"
                :rules="activationCodeRules"
                label="Kod aktywacyjny"
                type="text"
            ></v-text-field>
        </v-card-text>

        <v-card-actions class="action-buttons inline">
          <button class="button primary" :disabled="!valid" type="submit">
            <img
              class="icon"
              alt="profile icon"
              src="../../assets/images/icon-lock-open-16x16.svg"
              width="20"
              height="20"
            >

            <span class="button-label">Aktywuj Profil</span>
          </button>

          <span>lub</span>

          <a
            class="button outline"
            href="https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl"
            target="_blank"
          >
            <span class="button-label">Kup teraz</span>

            <v-icon>
              <img alt="" src="../../assets/images/external-link-icon-black-16x16.svg" width="16" height="16">
            </v-icon>
          </a>
        </v-card-actions>
      </v-form>
  </v-card>
</template>

<script>
import { toast } from "vue3-toastify";
import { emailPatternRule, fieldRequiredRule } from "@/utils/formValidators";
import axiosInstance from "@/interceptors/axios.config";

export default {
  data() {
    return {
      valid: false,
      email: '',
      activation_key: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
      activationCodeRules: [
        fieldRequiredRule(),
      ],
    };
  },
  methods: {
    async submitActivation() {
      const form = new FormData();
      form.append("email", this.email);
      form.append("activation_key", this.activation_key);

      try {
        const response = await axiosInstance.post(`${this.apiUrl}registration-business/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
          requiresReCaptcha: true,
          reCaptchaAction: 'register',
        });

        toast.success(response.data.message);

        setTimeout(() => {
          this.$router.push({ name: 'dashboard' });
        }, 3500);
      } catch (error) {
        console.error('Wystąpił błąd. Sprawdź dane i spróbuj ponownie.')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.memorial-profile-activation-form {
  max-width: 400px; /* Match the LoginForm.vue width */
  margin: 32px auto 104px;
  padding: 20px; /* Padding inside the form container */
  box-shadow: 0 0 10px rgba(#000000, 0.1); /* Shadow effect around the form */
  border-radius: 8px; /* Rounded corners */
  background: #FFFFFF; /* White background */

  @media (min-width: 768px) {
    margin: 80px auto 104px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  div {
    display: block; /* Override flex display */
    margin-bottom: 10px; /* Space between form groups */

    input {
      width: calc(100% - 22px); /* Adjust width to account for padding and border */
      padding: 10px; /* Adjust padding if needed */
      border: 1px solid #ccc; /* Add border to match login form style */
      border-radius: 4px; /* Slight rounding of corners to match login form style */
    }
  }

  input {
    font-weight: normal;
  }
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 15px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.activation-message {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
