<template>
  <v-container class="login-page">
    <v-card class="login-form" outlined>
      <v-card-title class="text-h5">Logowanie</v-card-title>
      <v-card-text>
        <!-- Email Field -->
        <v-text-field
            v-model="username"
            :rules="emailRules"
            label="E-mail"
            type="email"
        ></v-text-field>

        <!-- Password Field -->
        <v-text-field
            v-model="password"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="passwordRules"
            :type="isPasswordVisible ? 'text' : 'password'"
            label="Hasło"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="action-buttons">
        <button
          class="button primary"
          :disabled="isLoading || !isFormValid()"
          type="submit"
          @click="submitLogin"
        >
          {{ isLoading ? "Logowanie..." : "Zaloguj się" }}
          <img
            class="icon"
            alt="profile icon"
            src="../../assets/images/icon-user-circle-16x16.svg"
            width="20"
            height="20"
          >
        </button>

        <!-- Forgot Password Link -->
        <v-btn block class="link" color="blue" variant="text" @click="goToForgotPassword">Nie pamiętam hasła</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Terms and Conditions Modal -->
    <TermsAndConditionsAcceptanceDialog v-if="showTermsModal" />
  </v-container>
</template>


<script>
import {emailPatternRule, fieldRequiredRule, passwordPatternRule} from '@/utils/formValidators';
import {useAuthStore} from "@/stores/auth";
import TermsAndConditionsAcceptanceDialog from "@/components/Ui/TermsAndConditionsAcceptanceDialog.vue";

export default {
  components: {TermsAndConditionsAcceptanceDialog},
  data() {
    return {
      isPasswordVisible: false,
      isLoading: false,
      username: '',
      password: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
      passwordRules: [
        fieldRequiredRule(),
        passwordPatternRule(),
      ],
      showTermsModal: false,
    };
  },
  methods: {
    async submitLogin() {
      if (!this.isFormValid()) {
        return;
      }

      this.isLoading = true;

      const authStore = useAuthStore();
      try {
        await authStore.login({
          username: this.username,
          password: this.password,
        });

        if (!authStore.hasAcceptedLatestTerms) {
          // Show terms and conditions modal/dialog
          this.showTermsModal = true;
        } else {
          this.showTermsModal = false;
          // Navigate to the dashboard
          this.$router.push({ name: 'dashboard' });
        }
      } catch (error) {
        console.error('Login error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    goToForgotPassword() {
      this.$router.push({name: 'forgot-password'});
    },
    isFormValid() {
      return this.username && this.password && 
             !this.emailRules.some(rule => rule(this.username) !== true) &&
             !this.passwordRules.some(rule => rule(this.password) !== true);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  max-width: 400px;
  padding: 20px;
  margin: 32px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;

  @media (min-width: 768px) {
    margin: 80px auto 104px;
  }
}

.login-form {
  box-shadow: none;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form .form-group {
  display: block; /* Override flex display */
}

.login-form .form-group input {
  width: 100%; /* Full width for input fields */
  padding: 10px; /* Adjust padding if needed */
}

.login-form .button.primary {
  width: 100%; /* Full width for the button */
  padding: 10px 20px; /* Adjust padding if needed */
  margin-top: 10px; /* Space between buttons */
}

.login-form .forgot-password-text {
  text-align: center;
  color: black; /* Changed text color to black */
  cursor: pointer; /* Change cursor to pointer */
  font-size: 0.9em; /* Smaller font size */
  margin-top: 15px; /* Space after the login button */
}

.link {
  height: 24px;
  line-height: 150%;
  text-transform: none;
}
</style>
