<template>
  <v-container class="alert-box-wrapper">
    <v-alert :class="{ 'show-alert': showAlert }" :type="alertType" :value="true" class="alert-box">{{
        alertMessage
      }}
    </v-alert>
  </v-container>

  <v-container class="client-registration-form">
    <v-card-title class="text-h5">Rejestracja</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" @submit.prevent="submitRegistration">
        <v-text-field
            v-for="(field, index) in formFields"
            :key="index"
            v-model="field.model"
            :append-inner-icon="field.type === 'password' ? (passwordVisibility[field.modelName] ? 'mdi-eye-off' : 'mdi-eye') : undefined"
            :error-messages="field.errorMessages"
            :label="field.label"
            :rules="field.rules"
            :type="field.type === 'password' ? (passwordVisibility[field.modelName] ? 'text' : 'password') : field.type"
            @click:append-inner="field.type === 'password' ? passwordVisibility[field.modelName] = !passwordVisibility[field.modelName] : undefined"
        />

        <v-btn :disabled="!valid" size="large" type="submit" @click="submitRegistration">Załóż konto</v-btn>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import {emailPatternRule, fieldRequiredRule, passwordPatternRule, passwordsMatchRule} from '@/utils/formValidators';
import axiosInstance from "@/interceptors/axios.config";

export default {
  data() {
    return {
      valid: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      formFields: [
        {
          label: 'Email',
          type: 'email',
          modelName: 'email',
          model: '',
          rules: [
            fieldRequiredRule(),
            emailPatternRule(),
          ],
          errorMessages: ''
        },
        {
          label: 'Hasło',
          type: 'password',
          modelName: 'password', // Unique identifier for the password field
          model: '',
          rules: [
            fieldRequiredRule(),
            passwordPatternRule(),
          ],
          errorMessages: ''
        },
        {
          label: 'Powtórz hasło',
          type: 'password',
          modelName: 'repeatPassword',
          model: '',
          rules: [
            fieldRequiredRule(),
            passwordsMatchRule(() => this.formFields.find(field => field.label === 'Hasło').model),
          ],
          errorMessages: ''
        },
      ],
      apiUrl: process.env.VUE_APP_API_URL,
      passwordVisibility: {
        password: false,
        repeatPassword: false,
      },
    };
  },
  watch: {
    formFields: {
      handler: 'hideAlerts',
      deep: true,
    },
  },
  methods: {
    hideAlerts() {
      this.showAlert = false;
    },
    validateForm() {
      const errors = this.formFields.flatMap(field => {
        const validationResults = field.rules.map(rule => rule(field.model));
        const fieldErrors = validationResults.filter(result => result !== true);

        field.errorMessages = fieldErrors.join(', ');

        return fieldErrors;
      });
      return errors.length === 0;
    },

    prepareFormData() {
      return this.formFields.reduce((acc, field) => {
        acc[field.modelName] = field.model;
        return acc;
      }, {});
    },

    async submitRegistration() {
      if (!this.validateForm()) {
        return;
      }

      const formData = this.prepareFormData();

      if (formData.password !== formData.repeatPassword) {
        return;
      }

      try {
        await axiosInstance.post(`${this.apiUrl}registration-client/`, formData, {
          headers: {"Content-Type": "multipart/form-data"},
          requiresReCaptcha: true,
          reCaptchaAction: 'register_client',
        });

        this.showAlert = true;
        this.alertType = 'success';
        this.alertMessage = 'Rejestracja zakończona sukcesem.';

        setTimeout(() => {
          this.$router.push({name: 'home'});
        }, 1000);
      } catch (error) {
        this.showAlert = true;
        this.alertType = 'error';
        this.alertMessage = 'Wystąpił błąd. Sprawdź dane i próbuj ponownie.';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client-registration-form {
  max-width: 400px; /* Match the LoginForm.vue width */
  margin: 32px auto 104px;
  padding: 20px; /* Padding inside the form container */
  box-shadow: 0 0 10px rgba(#000000, 0.1); /* Shadow effect around the form */
  border-radius: 8px; /* Rounded corners */
  background: #FFFFFF; /* White background */

  @media (min-width: 768px) {
    margin: 80px auto 104px;
  }
}

.client-registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.client-registration-form div {
  display: block; /* Override flex display */
  margin-bottom: 10px; /* Space between form groups */
}

.client-registration-form div input {
  width: calc(100% - 22px); /* Adjust width to account for padding and border */
  padding: 10px; /* Adjust padding if needed */
  border: 1px solid #ccc; /* Add border to match login form style */
  border-radius: 4px; /* Slight rounding of corners to match login form style */
}

.client-registration-form button {
  width: 100%; /* Full width for the button */
  padding: 10px 20px; /* Adjust padding if needed */
  margin-top: 10px; /* Space between buttons */
  cursor: pointer; /* Change cursor to pointer */
  background-color: black; /* Button background color */
  color: white; /* Button text color */
  border: none; /* No border for the button */
  border-radius: 4px; /* Slight rounding of corners */
}

.client-registration-form button:hover {
  background-color: #333; /* Darken button on hover */
}

/* Make all text bold */
.client-registration-form label,
.client-registration-form button {
  font-weight: bold;
}

.client-registration-form input {
  font-weight: normal;
}

.error-message {
  color: red;
  text-align: center;
}

.success-message {
  color: green;
  text-align: center;
}

/* Position the message at the bottom of the form */
.client-registration-form form {
  position: relative;
}

.client-registration-form .error-message,
.client-registration-form .success-message {
  text-align: center;
  margin-top: 15px;
}

</style>
