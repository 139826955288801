<template>
  <header class="page-header" :class="{ 'is-profile': isProfileRoute }">
    <div class="content-wrapper">
      <v-img
          :src="require('images/logo.svg')" alt="Logo" class="logo" height="36" width="80"
          @click="goToPage('home')"
      />

      <div v-show="showMenu" class="menu-wrapper">
        <Menu :toggle-mobile-menu="toggleMobileMenu"></Menu>

        <v-divider class="menu-divider"></v-divider>

        <div v-if="isLoggedIn && isMobile" class="my-account-container">
          <button
              :class="{ active: route.path === '/panel-uzytkownika' }"
              class="menu-link"
              @click="goToPage('dashboard')"
          >Panel użytkownika
          </button>

          <button
              :class="{ active: route.path === '/aktywacja-profilu' }"
              class="menu-link"
              @click="goToPage('profile-activation')"
          >Aktywuj Profil Pamięci</button>

          <button
              :class="{ active: route.path === '/zmiana-hasla' }"
              class="menu-link"
              @click="goToPage('change-password')"
          >Zmień hasło
          </button>

          <button class="menu-link" @click="logout">Wyloguj się</button>
        </div>

        <div v-else-if="isLoggedIn" class="my-account-container">
          <div class="buttons-wrapper">
            <button class="button outline" @click="goToPage('profile-activation')">
              <img
                class="icon"
                alt="profile icon"
                src="../../assets/images/icon-lock-open-16x16.svg"
                width="20"
                height="20"
              >

              <span class="button-label">Aktywuj Profil</span>
            </button>

            <div class="button-wrapper">
              <button class="button outline my-account-button">
                <span class="button-label">Moje konto</span>

                <v-icon>
                  <img alt="profile icon" src="../../assets/images/icon-user-circle-16x16.svg" width="20" height="20">
                </v-icon>
              </button>
              <div class="dropdown-menu">
                <v-btn @click="goToPage('dashboard')">Panel użytkownika</v-btn>
                <v-btn @click="goToPage('change-password')">Zmień hasło</v-btn>
                <v-btn @click="logout">Wyloguj się</v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="auth-buttons-group">
          <button class="button outline" @click="goToPage('profile-activation')">
            <img
              class="icon"
              alt="profile icon"
              src="../../assets/images/icon-lock-open-16x16.svg"
              width="20"
              height="20"
            >

            <span class="button-label">Aktywuj Profil</span>
          </button>
          <div class="login-container">
            <button class="button primary login" @click="goToPage('login')">
              <span class="button-label">Zaloguj się</span>

              <img 
                class="icon" 
                alt="profile icon" 
                src="../../assets/images/icon-user-circle-16x16.svg"
                width="20"
                height="20"
              >
            </button>
          </div>
        </div>
      </div>

      <button v-if="isMobile" :class="{'hamburger': true, 'open': mobileMenuActive}" @click="toggleMobileMenu">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </header>
</template>

<script>
import {computed, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";
import {useMediaQuery} from '@vueuse/core';
import {useAuthStore} from "@/stores/auth";
import Menu from "@/components/Layout/Menu.vue";

export default {
  components: {Menu},
  setup() {
    const isLoggedIn = ref(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const mobileMenuActive = ref(false);
    const authStore = useAuthStore();
    const route = useRoute();

    const isProfileRoute = computed(() => {
      return route.path.startsWith('/profil');
    });

    watchEffect(() => {
      isLoggedIn.value = authStore.isLoggedIn;
    });

    const toggleMobileMenu = () => {
      mobileMenuActive.value = !mobileMenuActive.value;
    };

    const closeMobileMenu = () => {
      mobileMenuActive.value = false;
    };

    const showMenu = computed(() => {
      return mobileMenuActive.value || !isMobile.value;
    });

    return {
      isLoggedIn,
      isMobile,
      mobileMenuActive,
      toggleMobileMenu,
      closeMobileMenu,
      showMenu,
      route,
      authStore,
      isProfileRoute,
    };
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.closeMobileMenu();
    },
    goToPage(page) {
      this.closeMobileMenu();
      this.$router.push({name: page});
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  position: relative;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 1px 2px rgba(22, 22, 22, 0.1);
  left: 0;
  right: 0;

  &:not(.is-profile) {
    position: -webkit-sticky;
    position: sticky;
  }

  @media (min-width: 768px) {
    height: 80px;
    padding: 10px 20px 0;
  }

  :global(body:has(.memorial-profile-container)) & {
    position: relative;
    box-shadow: none;
  }


  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;

    &-box {
      position: relative;
      width: 24px;
      height: 18px;
    }

    &-inner {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #1C1B1B;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #1C1B1B;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }

    &.open {
      .hamburger-inner {
        background-color: transparent;

        &::before {
          transform: translateY(6px) rotate(45deg);
        }

        &::after {
          transform: translateY(-6px) rotate(-45deg);
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 64px;
  }

  .content {
    justify-content: flex-start;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }
}

.logo {
  width: 100%;
  max-width: 100px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: fit-content;
    height: 36px !important;
  }
}

.menu-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 32px;
  background-color: #ffffff;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  height: calc(100vh - 40px);

  @media (min-width: 768px) {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: unset;
    right: unset;
    height: 100%;
    gap: 32px;
    padding: 0;
    box-shadow: none;
    overflow: unset;
  }
}

.menu-divider {
  margin: 8px 0;

  @media (min-width: 768px) {
    display: none;
  }
}

.menu-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.button-wrapper {
  height: 100%;
  position: relative;
  
  &:hover {
    .dropdown-menu {
      display: flex;
      opacity: 1;
    }
  }
}

.button {
  white-space: nowrap;
  width: 150px;
  justify-content: center;

  @media (min-width: 768px) {
    width: auto;
  }
}

.my-account-button {
  text-transform: capitalize;
  font-weight: 500;
}

.my-account-container {
  height: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 0;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    padding-bottom: 8px;
    justify-content: flex-end;
  }
}

.login-container {
  position: relative;
  
  &:hover {
    .dropdown-menu {
      display: flex;
      opacity: 1;
    }
  }
}

.auth-buttons-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 2rem;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    margin-top: 0;
    justify-content: flex-start;
  }
}

.horizontal-separator {
  margin: 0 24px 0 0;
  border: 0;
  border-top: 1px solid #000000;
  opacity: 1;
}

.dropdown-menu {
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 100%;
  right: 0;
  opacity: 0;
  padding: 10px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.3s ease;

  button {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    font-size: 16px;
    text-transform: unset;
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }
}

.menu-link {
  padding: 12px 32px 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 0.875rem;
  border-right: 4px solid transparent;
  color: #1C1B1B;
  white-space: nowrap;
  text-decoration: none;

  @media (min-width: 768px) {
    padding: 12px 16px 8px;
    border-radius: 10px 10px 0 0;
    color: #000000;
  }

  &:hover {
    background-color: transparent;
    border-color: #c0c0c0;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #c0c0c0;
      border-color: transparent;
    }
  }

  &.active {
    border-color: #000000;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #000000;
      border-color: transparent;
      color: #ffffff;
      cursor: default;
    }
  }
}
</style>
